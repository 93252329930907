// import { Suspense, memo } from 'react';
import { Text, Modal, Box } from '@marketmuse/components';
// import { PAPI } from '@marketmuse/config/types';
import {
  useStoreSelector,
  // useStoreDispatch,
} from '@marketmuse/data-state/hooks';

import { SubscriptionPackages } from './SubscriptionPackages';

const ExpiredNonTrialModal = () => {
  const subscription = useStoreSelector(state => state.payment.subscription);
  const siteId = useStoreSelector(state => state.filter?.site?.id);

  return (
    <Modal
      isVisible={true}
      onClose={() => {
        // do nothing
      }}
      disableClose={true}
      closeBtnClassName={['hidden']}
      panelClassName={['w-fit', 'max-w-fit', 'p-10']}
    >
      <Box>
        <Box className={['prose', 'mb-4']}>
          <Text as="h1" className={['text-2xl', 'mb-2']}>
            Reactivate your MarketMuse account
          </Text>
          <Text as="p">
            Your account has expired, or been terminated. Downgrade to free for
            limited access, or reactivate on any of our plans.
          </Text>
        </Box>
        <SubscriptionPackages
          subscriptionId={subscription?.id || ''}
          siteId={siteId || ''}
          wrapperClassNames={[
            'flex',
            'flex-row',
            'gap-10',
            'w-full',
            'justify-center',
          ]}
          cardClassNames={['w-[320px]']}
          dataTags={{
            ctaUpgrade: {
              'data-mms--subscription-canceled-action': 'upgrade',
            },
            ctaDowngrade: {
              'data-mms--subscription-canceled-action': 'exit-to-free',
            },
          }}
        />
      </Box>
    </Modal>
  );
};
export default ExpiredNonTrialModal;
