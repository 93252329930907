import { memo } from 'react';
import styled from 'styled-components';
import { Badge, Icon, SUPPORTED_ICON, Text, Box } from '@marketmuse/components';

import Portal from '../../../components/Portal';

import Modal, { ModalBody } from '../../../components/Modal';

const ModalStyled = styled(Modal)`
  position: relative;
  z-index: 2000;
`;

const ModalBodyStyled = styled(ModalBody)`
  width: fit-content;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding: ${p => p.theme.spacing.xLarge};
  display: flex;
  column-gap: 80px;
`;

const ExpiredDemoModal = () => {
  return (
    <Portal>
      <ModalStyled
        closeStyle={{ zIndex: 999 }}
        hideClose={true}
        backdropAttrs={{ style: { zIndex: 999999999 } }}
        useDiv
        style={{ width: 'unset' }}
      >
        <ModalBodyStyled
          onKeyDown={event => event.key === 'Enter' && event.stopPropagation()}
          className="mm-tw"
        >
          <Box className={['flex', 'flex-col', 'justify-center']}>
            <Badge color="red" shade={'light'} size={'base'}>
              <Box className={['flex', 'gap-x-2', 'items-center']} as="span">
                <Icon
                  name={SUPPORTED_ICON.warning_triangle}
                  color="text-red-400"
                />
                <Text>Your demo has expired.</Text>
              </Box>
            </Badge>
            <Box className={['flex', 'flex-col']}>
              <Text className={['text-6xl', 'font-bold', 'mt-6']}>
                Please contact your sales representative to continue to continue
                using MarketMuse.
              </Text>
            </Box>
          </Box>
        </ModalBodyStyled>
      </ModalStyled>
    </Portal>
  );
};

export default memo(ExpiredDemoModal);
