import { Suspense } from 'react';

import { lazyLoad } from '@marketmuse/utilities';
import ScreenLoader from '../../components/ScreenLoader';

import ExpiredTrialModal from '../Payments/SubscriptionStatus/ExpiredTrialModal';
import ExpiredDemoModal from '../Payments/SubscriptionStatus/ExpiredDemoModal';
import ExpiredNonTrialModal from '../Payments/SubscriptionStatus/ExpiredNonTrialModal';

import AppModalsMiscState from './AppModalsMiscState';

const SubscriptionDunning = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SubscriptioDunning' */ '@marketmuse/account-subscription/SubscriptionDunning'
    ),
);

const LayoutGated = ({
  mode,
}: {
  mode: 'DUNNING' | 'EXPIRED_TRIAL' | 'EXPIRED_DEMO' | 'EXPIRED';
}) => {
  return (
    <>
      <AppModalsMiscState />
      <ScreenLoader.Auth>
        {mode === 'EXPIRED' && <ExpiredNonTrialModal />}
        {mode === 'EXPIRED_TRIAL' && <ExpiredTrialModal />}
        {mode === 'EXPIRED_DEMO' && <ExpiredDemoModal />}
        {mode === 'DUNNING' && (
          <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
            <SubscriptionDunning />
          </Suspense>
        )}
      </ScreenLoader.Auth>
    </>
  );
};

export default LayoutGated;
